import styled from '@emotion/styled';
import Section from '../atoms/Section';
import SectionHeader from '../atoms/SectionHeader';
import Text from '../atoms/Text';

const SchedulingSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SchedulingSubsection = styled.div`
  @media (max-width: 1025px) {
    width: 100%;
    justify-content: flex-start;
    min-height: unset;
  }
  min-height: 200px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.3rem;
`;

const BookingButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  background: #3a668c;
  border: none;
  width: 60%;
  height: 60px;

  @media (max-width: 1025px) {
    width: 85%;
  }

  &:hover {
    cursor: pointer;
    background: #588bb7;
  }
`;

const BookingButtonText = styled.p`
  font-family: 'Avenir', sans-serif;
  font-weight: 200;
  font-size: 1.5rem;
  color: #ffffff;
`;

const Scheduling = () => {
  const handleBookingOnClick = () => {
    window.open(
      'https://squareup.com/appointments/book/v37vbtvrzl4jjj/LVSEDWJZPRZZG/start',
      '_blank'
    );
  };

  const handleShoppingOnClick = () => {
    window.open('https://adorned-by-emily.square.site/s/shop', '_blank');
  };

  return (
    <SchedulingSection id='scheduling'>
      <SectionHeader>{`SCHEDULING & SHOPPING`}</SectionHeader>
      <Section>
        <SchedulingSubsection>
          <Text
            style={{
              fontWeight: 'bold',
            }}
          >
            Booking by appointment only
          </Text>
          <BookingButton
            style={{ marginBottom: '30px' }}
            onClick={handleBookingOnClick}
          >
            <BookingButtonText>Book an appointment now</BookingButtonText>
          </BookingButton>
        </SchedulingSubsection>
        <SchedulingSubsection>
          <Text
            style={{
              fontWeight: 'bold',
            }}
          >
            Now selling jewelry online!
          </Text>
          <BookingButton onClick={handleShoppingOnClick}>
            <BookingButtonText>Click here to shop online</BookingButtonText>
          </BookingButton>
        </SchedulingSubsection>
      </Section>
    </SchedulingSection>
  );
};

export default Scheduling;
